<template>
  <div class="c-app flex-row align-items-center bo">
    <center-dialogs/>
    <CContainer>
      <CRow class="justify-content-center">
        <CCol md="8">
          <CCardGroup>
            <CCard class="p-4">
              <CCardBody>
                <CForm>
                  <div class="text-center mb-3">
                    <img src="@/assets/logo.svg" height="150px"/>
                  </div>
<!--                  <h1>Login</h1>-->
                  <p class="text-muted text-center">Sign In to your account</p>
                  <CInput
                    v-model="username"
                    placeholder="Username"
                    autocomplete="username email"
                  >
                    <template #prepend-content><CIcon name="cil-user"/></template>
                  </CInput>
                  <CInput
                    v-model="password"
                    placeholder="Password"
                    type="password"
                    autocomplete="curent-password"
                  >
                    <template #prepend-content><CIcon name="cil-lock-locked"/></template>
                  </CInput>
                  <CRow>
                    <CCol  class="text-right">
                      <CButton color="danger" class="px-4 w-50" @click="this.onAuthen">Login</CButton>
                    </CCol>
                  </CRow>
                </CForm>
              </CCardBody>
            </CCard>
            <CCard
              color="danger"
              text-color="white"
              class="text-center py-5 d-md-down-none "
              body-wrapper
            >
              <CCardBody>
                <p>{{ $t('section', {index: 'Lokalise'}) }}</p>
<!--                <h2>Sign up{{$i18n.locale = 'es'}}</h2>-->
                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                <CButton
                  color="light"
                  variant="outline"
                  size="lg"
                >
                  Register Now!
                </CButton>
              </CCardBody>
            </CCard>
          </CCardGroup>
        </CCol>
      </CRow>
    </CContainer>


  </div>
</template>

<script>
  import {mapGetters} from 'vuex'
  import CenterDialogs from "../../containers/CenterDialogs";
  export default {
    name: 'Login',
    components: {CenterDialogs},
    data () {
      return {
        username:'48212025',
        password:'1',
        dialog : {
          statue:false,
          title:"",
          message:""
        }

      }
    },
    methods: {
      onAuthen(){
        var body = {};
        body.username = btoa(this.username);
        body.password = btoa(this.password);
        this.$store.dispatch("auth/onLogin", body)

      }
    },

    computed:{
      ...mapGetters({
        objJwt:'auth/objJwt',
      })
    },

    watch: {
      objJwt:function (data) {
        console.log(data)

      }
    }
  }

</script>
<style>
.bo{
  background-color: #321FDB;
  background-image: url("../../assets/bg.jpeg");
  /*background-size: auto;*/
  background-size: 100% 100%;
}

.bos{
  height: 100%;
/* max-height: 600px; */
  width: 100%;
  background-color: hsla(200,40%,30%,.4);
  background-image:
    url("../../assets/bg.jpeg");
    /*url('https://78.media.tumblr.com/66445d34fe560351d474af69ef3f2fb0/tumblr_p7n908E1Jb1uy4lhuo1_1280.png'),*/
    /*url('https://78.media.tumblr.com/8cd0a12b7d9d5ba2c7d26f42c25de99f/tumblr_p7n8kqHMuD1uy4lhuo2_1280.png'),*/
    /*url('https://78.media.tumblr.com/5ecb41b654f4e8878f59445b948ede50/tumblr_p7n8on19cV1uy4lhuo1_1280.png'),*/
    /*url('https://78.media.tumblr.com/28bd9a2522fbf8981d680317ccbf4282/tumblr_p7n8kqHMuD1uy4lhuo3_1280.png');*/
  background-repeat: repeat-x;
  background-position:
    0 20%,
    0 100%,
    0 0;
  background-size:
    2500px,
    800px,
    500px 200px,
    1000px,
    400px 260px;
  animation: 50s para infinite linear;
}

@keyframes para {
  100% {
    background-position:
      -5000px 20%,
      -800px 95%,
      500px 50%,
      1000px 100%,
      400px 0;
  }
}
</style>
